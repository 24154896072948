<template>
    <div class="p-4">
        <top-search></top-search>

        <div v-if="riders" class="w-full my-12 px-4">
            <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1 ">
                            <h3 class="font-semibold text-lg">Riders</h3>
                        </div>
                    </div>
                </div>
                <div class="block w-full h-96 overflow-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th class="px-4 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">#</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Name</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Address</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">City</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date created</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Status</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="bg-white" v-for="(rider, index) in riders" :key="rider.id">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <span class="font-bold"> {{ index + 1 }} </span>
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ rider.user.first_name }} {{ rider.user.last_name }} </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ rider.business_address }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ rider.city.name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ moment(rider.created_at).format('DD, MM YYYY') }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ rider.status }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">                                    
                                    <div class="flex justify-center">
                                        <div>
                                            <div class="dropstart relative">
                                                <button @click="d_rider = rider" 
                                                    class="dropdown-toggle text-brown-default font-bold text-lg uppercase focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">...
                                                </button>
                                                <ul data-bs-toggle="modal" data-bs-target="#exampleModal" class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left
                                                    rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none" aria-labelledby="dropdownMenuButton1">
                                                    <li @click="gotoRiderDetails()">
                                                        <p class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 cursor-pointer">
                                                            View
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 cursor-pointer">
                                                            Suspend
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 cursor-pointer">
                                                            Unsuspend
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 cursor-pointer">
                                                            Delete
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'
import moment from 'moment'

export default {
    name: 'Rider',
    components: {
        TopSearch,
        SquareGrid,
    },
    data() {
        return {
            d_rider: null,
            moment: moment
        }
    },
    computed: {
        ...mapGetters({riders: 'ridersModule/getRiders'})
    },
    methods: {
        riderUpdateRequest() {
            this.$store.dispatch('ridersModule/riderUpdateRequest')
        },
        gotoRiderDetails() {
            this.$store.dispatch('ridersModule/setRiderRequest', this.d_rider)
            this.$router.push({name: 'Rider_Details'})
        }
    }
}
</script>